<template>
  <a-spin :spinning="loading">
    <div class="wrapper page-wrap">
      <video
        controls
        :currentTime="course_record"
        id="myvideo"
        style="width: 1200px; height: 600px"
        v-if="course_resource.resource_video"
        :src="course_resource.resource_video"
      ></video>
      <div v-if="course_resource.resource_video" class="blue-line"></div>
      <div
        style="width: 1200px; padding: 20px 0"
        class="nn"
        v-html="course_resource?.resource_content"
      ></div>
    </div>
  </a-spin>
</template>
<script setup>
import { getCourseResourceData, setCourseRecordData } from "@/api/main.js";
import { useStore } from "vuex";
import {
  ref,
  createVNode,
  onUnmounted,
  onMounted,
  nextTick,
  onUpdated,
} from "vue";
import url from "@/utils/url.js";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";
import { message, Modal, notification } from "ant-design-vue";
const route = useRoute();
const visible = ref(false);
let query = decode(route.query?.t).split(",");
// console.log(query);
const store = useStore();
const loading = ref(true);
const course_record = ref();
const course_resource = ref({});
const num = ref(0);
let timer;
let timer2;
getCourseResourceData({
  resource_id: query[0],
  course_id: query[1],
  user_id: store.state.user.user_id,
}).then((res) => {
  if (res.code == 200) {
    loading.value = false;
    course_resource.value = res.data.course_resource;
    course_record.value = res.data.course_record.record_duration;
    num.value = res.data.course_record.record_duration+1;
    timer = setInterval(() => {
      num.value += 1;
    }, 1000);
  } else {
    message.error(res.msg);
  }
});
timer2 = setInterval(() => {
  setCourseRecordData({
    resource_id: query[0],
    course_id: query[1],
    user_id: store.state.user.user_id,
    record_duration: num.value,
  })
    .then()
    .catch((rerr) => {
      message.error("学习进度上报失败");
    });
}, 10000);
onUnmounted(() => {
  clearInterval(timer);
  timer = null;
  clearInterval(timer2);
  timer2 = null;
});
onUpdated(() => {
  let V = document.getElementById("myvideo");

  // if (V) {
  //   V.addEventListener("timeupdate", (e) => {
  //     let S = Math.floor(V.currentTime);
  //     if (S > num.value) {
  //       message.info("您还没有观看完整视频，无法快进");
  //       V.currentTime = num.value;
  //     }
  //   });
  // }
});
</script>
<style scoped lang="less">
.blue-line {
  width: 1200px;
  height: 10px;
  background-color: #f3f5f8;
}
.nn {
  img {
    max-width: 1200px;
  }
}
</style>